<template>
  <div class="main copy-btn" :data-clipboard-text="`@${clipboardText}@`" @click="download">
    <div class="top"></div>

    <div class="center">
      <div class="btn">立即下载领取VIP</div>

      <div class="img-block">
        <img
          class="img-one"
          v-for="(item, index) in 6"
          :key="index"
          :src="require(`../../../assets/images/app/theme${index + 1}.png`)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import Clipboard from 'clipboard';
  export default {
    data() {
      return {
        clipboardText: this.$route.query.invite_code || '',
      };
    },
    methods: {
      download() {
        let system = navigator.userAgent.toLowerCase();
        if (/(iPhone|iPad|iPod|iOS)/i.test(system)) {
          const clipboard = new Clipboard('.copy-btn');
          clipboard.on('success', (e) => {
            e.clearSelection();
            this.$toast('复制成功');
            window.location.href = 'https://apps.apple.com/cn/app/1565296362';
          });
          clipboard.on('error', () => {
            this.$toast('复制失败');
          });
        } else {
          this.$toast('安卓版开发中，敬请期待');
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .top {
    width: 7.5rem;
    height: 6.24rem;
    background: url('~@/assets/images/app/top-bg.png') no-repeat center center;
    background-size: 100% 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  .center {
    background: #000000;
    padding-top: 0.46rem;
    padding-bottom: 0.2rem;
    .btn {
      margin: 0 auto;
      width: 5.7rem;
      height: 1.06rem;
      background: #f76693;
      border-radius: 0.53rem;

      font-size: 0.36rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 1.06rem;
      text-align: center;
    }
    .img-block {
      margin: 0.58rem 0.76rem 0 0.76rem;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      .img-one {
        width: 2.74rem;
        height: 5.94rem;
        margin-bottom: 0.4rem;
      }
    }
  }
</style>
