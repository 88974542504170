// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/app/top-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".top[data-v-3a58bc55]{width:7.5rem;height:6.24rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:100% 100%;display:flex;flex-flow:column nowrap;align-items:center}.center[data-v-3a58bc55]{background:#000;padding-top:.46rem;padding-bottom:.2rem}.center .btn[data-v-3a58bc55]{margin:0 auto;width:5.7rem;height:1.06rem;background:#f76693;border-radius:.53rem;font-size:.36rem;font-family:PingFangSC-Semibold,PingFang SC;font-weight:600;color:#fff;line-height:1.06rem;text-align:center}.center .img-block[data-v-3a58bc55]{margin:.58rem .76rem 0 .76rem;display:flex;flex-flow:row wrap;justify-content:space-between}.center .img-block .img-one[data-v-3a58bc55]{width:2.74rem;height:5.94rem;margin-bottom:.4rem}", ""]);
// Exports
module.exports = exports;
